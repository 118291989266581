import styles from "./style.module.scss";
import constants from "../../../asset/constants";
import { useParams } from "react-router";
import kakaoShareBtnImg from "../../../asset/Img/kakaoShareBtn.png";
import linkShareBtnImg from "../../../asset/Img/icon_link.png";
import util from "../../../asset/util";

const LinkShareModal = ({ isLinkShareModal, setIsLinkShareModal, qnaData }) => {
  const { Kakao } = window;
  const params = useParams();
  const redirectUrl = `${constants.redirect_url}talk/student/null/${params["qid"]}/${params["sid"]}/${params["tid"]}`;
  const handelCloseLinkShareModal = (event) => {
    event.stopPropagation();
    event.defaultPrevented;
    setIsLinkShareModal(false);
  };
  const handleNotingEvent = (event) => {
    event.stopPropagation();
    event.defaultPrevented;
  };
  const handleTalkLinkSendToKakao = async () => {
    Kakao.Share.sendDefault({
      objectType: "feed",
      content: {
        title: qnaData.title ?? "(출처 없는 질문)",
        description: qnaData.name ?? "",
        imageUrl: `${constants.s3_url}/qna/${qnaData.original !== 0 ? qnaData.original_img : qnaData.img}.jpg`,
        link: {
          mobileWebUrl: redirectUrl,
          webUrl: redirectUrl,
        },
      },
      buttons: [
        {
          title: "문제 확인 하기",
          link: {
            mobileWebUrl: redirectUrl,
            webUrl: redirectUrl,
          },
        },
      ],
    });
  };
  const handleCopyTalkLinkAndImg = async () => {
    await util.copyClip(redirectUrl);
    alert("링크가 복사됐습니다.");
    // const textBlob = new Blob([redirectUrl], {type: "text/plain"});
    // let clipboardItems = {"text/plain": textBlob}
    // const imgUrl = await (await fetch(`${constants.apiUrl}/qna/${qnaData.original !== 0 ? qnaData.original_img : qnaData.img}.jpg?raw=true`)).json();
    // if (imgUrl) {
    //     const blob = await (await fetch(imgUrl)).blob()
    //     const imgBlob = blob.slice(0, blob.size, "image/png")
    //     const img = imgBlob
    //     clipboardItems['text/html'] = imgBlob
    // }
    // console.log(clipboardItems)
    // const newClipboardItems = [new ClipboardItem(clipboardItems)];
    // await navigator.clipboard.write(newClipboardItems)
  };

  return (
    <div className={styles.MainContainer} onClick={handelCloseLinkShareModal}>
      {isLinkShareModal && (
        <div className={styles.CardSection} onClick={handleNotingEvent}>
          <div>링크 공유</div>
          <div className={styles.BtnWrapper}>
            <button type={"button"} onClick={handleTalkLinkSendToKakao}>
              <img src={kakaoShareBtnImg} />
            </button>
            <button type={"button"} onClick={handleCopyTalkLinkAndImg}>
              <img className={styles.linkImg} src={linkShareBtnImg} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LinkShareModal;
