import type { Inventory } from "pages/MyInfo/Ticket";
import { createContext, Dispatch } from "react";
import { ForSaleTicketsQuery } from "types/graphql/graphql";

interface ICommonContext {
  qnaData?: any;
  setQnaData?: Dispatch<React.SetStateAction<any>>;
  fileObj?: any;
  setFileObj?: Dispatch<React.SetStateAction<any>>;
  isFull?: boolean;
  setIsFull?: Dispatch<React.SetStateAction<boolean>>;
  bookInQuestionList?: any;
  setBookInQuestionList?: Dispatch<React.SetStateAction<any>>;
}
export const CommonContext = createContext<ICommonContext>({});

export type OpenMode = "select" | "purchase" | "both" | "none";

interface IPaymentContext {
  academyId?: string;
  setAcademyId?: Dispatch<React.SetStateAction<string>>;
  defaultAcademyId?: string;
  setDefaultAcademyId?: Dispatch<React.SetStateAction<string>>;
  isPaymentModalOpen?: boolean;
  setPaymentModalOpen?: Dispatch<React.SetStateAction<boolean>>;
  userInfo?: any;
  inventories?: Inventory[];
  setInventories?: Dispatch<React.SetStateAction<any>>;
  tickets?: ForSaleTicketsQuery["forSaleTickets"];
  setTickets?: Dispatch<React.SetStateAction<ForSaleTicketsQuery["forSaleTickets"]>>;
  updatedAt?: any;
  setForceUpdate?: Dispatch<React.SetStateAction<any>>;
  paymentOpen?: OpenMode;
  setPaymentOpen?: Dispatch<React.SetStateAction<OpenMode>>;
  pickResult?: any;
  setPickResult?: Dispatch<React.SetStateAction<any>>;
  requestPickTicket?: () => void;
  isSelectTeacherModalOpen?: boolean;
  setIsSelectTeacherModalOpen?: Dispatch<React.SetStateAction<boolean>>;
  selectedLectureId?: number | null;
  setSelectedLectureId?: Dispatch<React.SetStateAction<number | null>>;
  selectedTeacherId?: string;
  setSelectedTeacherId?: Dispatch<React.SetStateAction<string>>;
  cleanUpPayment?: () => void;
}

export const PaymentContext = createContext<IPaymentContext>({});
