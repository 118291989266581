import { atom } from "recoil";
import { AtomKeys } from "../constants";
import { EditModalEnum, EditModalType, QuestionTypeModalType } from "./types";

export const editShowModalAtom = atom<EditModalType>({
  key: AtomKeys.EDIT_SHOW_MODAL,
  default: EditModalEnum.EMPTY,
});

export const captureStateModalAtom = atom<boolean>({
  key: AtomKeys.CAPTURE_STATE_MODAL,
  default: false,
});

export const questionTypeModalAtom = atom<QuestionTypeModalType>({
  key: AtomKeys.QUESTION_TYPE_MODAL,
  default: {
    isOpen: false,
    images: [],
  },
});
