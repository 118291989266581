export enum EditModalEnum {
  EMPTY,
  QUESTION,
  ANSWER,
}

export type EditModalType = EditModalEnum.QUESTION | EditModalEnum.ANSWER | EditModalEnum.EMPTY;

export type QuestionTypeModalType = {
  isOpen: boolean;
  images: File[];
};
