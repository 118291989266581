import constants from "../../asset/constants";
import { axiosInstance } from "../../api/common";
import apiFn from "../../asset/apiClass";

export class CreateQuestionService {
  static async createQuestionData({ questionData }: { questionData: any }) {
    const defaultBookId = await this._returnDefaultBookId(questionData.lectureId);
    const images = await this._uploadQuestionImages(questionData.studentId, questionData.images);

    return {
      bookId: defaultBookId,
      teacherId: questionData.teacherId,
      studentId: questionData.studentId,
      images: images,
      academyId: questionData.academyId,
    };
  }
  private static async _returnDefaultBookId(lectureId: string) {
    try {
      const response = await axiosInstance.get(`${constants.apiUrl}/class/${lectureId}/default`);
      return response.data.bid_HIQDB_book.id;
    } catch (e: any) {
      throw new Error(e);
    }
  }
  private static async _uploadQuestionImages(studentId: string, images: File[]) {
    return await Promise.all(
      images.map((image) => apiFn.uploadFile(`qna/temp-${studentId}/${image.name}`, image))
    );
  }
}
