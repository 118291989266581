import { useLazyQuery } from "@apollo/client";
import { CameraAlt } from "@material-ui/icons";
import { Avatar } from "@mui/material";
import { PaymentContext } from "asset/context";
import { getUser } from "asset/storage";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  GetTeacherFilteredByTicketDocument,
  GetUserByLectureDocument,
} from "types/graphql/graphql";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import face_teacher from "../../../asset/Img/defaultpic_teacher.png";
import constants from "../../../asset/constants";
import style from "../../../asset/style";
import UiMessage_Style from "../../../uiComponents/UiMessage/UiMessage_Style";
import UiTextBtn from "../../../uiComponents/UiTextBtn/UiTextBtn";
import styles from "./style.module.scss";
import { useSetRecoilState } from "recoil";
import { questionTypeModalAtom } from "../../../application/recoils/modal/atoms";

const SelectTeacherModal = ({ onSubmit, onCancel }) => {
  const userInfo = getUser();

  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const setOpenQuestionTypeModal = useSetRecoilState(questionTypeModalAtom);
  const { setIsSelectTeacherModalOpen, setSelectedTeacherId } = useContext(PaymentContext);

  const { pickResult, setPickResult, selectedLectureId, academyId } = useContext(PaymentContext);

  // 결제 기능 off 상태일 때의 선생님 목록
  const [getTeachers, { data: teachers, loading: getTeachersLoading }] =
    useLazyQuery(GetUserByLectureDocument);
  // 결제 기능 on 상태일 때의 선생님 목록
  const [
    getTeacherFilteredByTicket,
    { data: teachersByTicket, loading: getTeacherFilteredByTicketLoading },
  ] = useLazyQuery(GetTeacherFilteredByTicketDocument);

  const teacherList = useMemo(() => {
    if (!teachers && !teachersByTicket) return [];
    if (pickResult) {
      return teachersByTicket.getTeacherFilteredByTicket.teachers;
    } else {
      const lectureList =
        teachers?.getUserByLecture?.user[0]?.HIQDB_user_credentials?.[0]?.HIQDB_member_classes ||
        [];
      const users = lectureList.flatMap((item) =>
        (item.cid_HIQDB_class?.HIQDB_member_classes || []).map((memberClass) => ({
          ...memberClass.mid_HIQDB_user_credential.user,
          status: memberClass.status,
        }))
      );
      const uniqueTeacherIds = Array.from(
        new Set(
          users.filter((user) => user.user_type === 2 && user.status === 1).map((user) => user.id)
        )
      );
      return uniqueTeacherIds.map((id) => users.find((user) => user.id === id)).filter(Boolean);
    }
  }, [teachers, teachersByTicket, pickResult]);

  useEffect(() => {
    if (pickResult) {
      const selectedLectureData = selectedLectureId
        ? { lectureId: selectedLectureId?.toString() }
        : {};
      getTeacherFilteredByTicket({
        variables: {
          studentId: userInfo.id,
          ticketType: pickResult,
          academyId: academyId,
          ...selectedLectureData,
        },
        fetchPolicy: "network-only",
      });
    } else {
      getTeachers({
        variables: {
          userId: userInfo.id.toString(),
          academyId: constants.classroomId?.toString() ?? null,
          lectureId: selectedLectureId?.toString(),
        },
        fetchPolicy: "network-only",
      });
    }
  }, [pickResult, userInfo.id, getTeacherFilteredByTicket, getTeachers]);

  useEffect(() => {
    if (teacherList.length === 1) {
      setSelectedTeacherId(teacherList[0].id);
      setSelectedTeacher(teacherList[0]);
    }
  }, [teacherList]);

  return (
    <button
      className={styles.ToSAgreementModalContainer}
      style={{ ...UiMessage_Style.section_r_t_t_text_1, border: "none" }}
    >
      <div className={styles.ToSAgreementModalContent}>
        <div
          style={{
            ...UiMessage_Style.section_r_t_t_text_3,
            fontSize: 18,
            marginBottom: 16,
            marginLeft: 10,
            color: "#333",
            textAlign: "left",
          }}
        >
          {selectedTeacher ? "질문할 선생님" : "어떤 선생님께 질문할까요?"}
        </div>
        {selectedTeacher ? (
          <div
            style={{
              marginBottom: 8,
              padding: 12,
              display: "flex",
              height: 32,
              alignItems: "center",
              border: "1px #fafafa solid",
            }}
          >
            <Avatar
              style={{ marginRight: 10 }}
              src={constants.apiUrl + `/teacher/${selectedTeacher.id}.jpg`}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget.src = face_teacher;
              }}
            />
            {`${selectedTeacher.name} (${selectedTeacher.nick})`}
          </div>
        ) : (
          <div
            style={{
              height: "80%",
              overflow: "auto",
              border: "1px solid #fdfdfd",
            }}
          >
            {getTeachersLoading || getTeacherFilteredByTicketLoading ? (
              <UiLoading
                isTransparent={true}
                isText={false}
                isCircle={false}
                text="선생님을 불러오는 중입니다."
              />
            ) : (
              <>
                {teacherList?.length === 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: 100,
                      fontSize: 18,
                    }}
                  >
                    수업에 참여 중인 선생님이 없습니다.
                  </div>
                )}
                {pickResult &&
                  teacherList.length > 0 &&
                  (teacherList as any)?.every((teacher: any) => teacher.isExhausted) && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 100,
                        fontSize: 18,
                      }}
                    >
                      <div>
                        <p>모든 선생님의 질문이 마감되었어요.</p>
                        <p>내일 다시 질문해주세요.</p>
                      </div>
                    </div>
                  )}
                {teacherList?.map((teacher) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        border: "1px #fafafa solid",
                        borderRadius: 4,
                        padding: 8,
                      }}
                      key={`select_teacher_${teacher.id}`}
                    >
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          alignItems: "center",
                        }}
                      >
                        <Avatar
                          style={{ marginRight: 10 }}
                          src={constants.s3_url + `/teacher/${teacher.id}.jpg`}
                          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                            e.currentTarget.src = face_teacher;
                          }}
                        />
                        {`${teacher.name} (${teacher.nick})`}
                      </div>
                      <div style={{ alignContent: "center" }}>
                        {pickResult ? (
                          teacher.isExhausted ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "25px",
                                borderRadius: "15px",
                                padding: "5px 15px",
                                fontSize: "14px",
                                color: "#FFFFFF",
                                fontWeight: 400,
                                margin: "0px 5px",
                                cursor: "not-allowed",
                                backgroundColor: "#D8D8D8",
                              }}
                            >
                              질문하기
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "25px",
                                borderRadius: "15px",
                                padding: "5px 15px",
                                fontSize: "14px",
                                color: "#FFFFFF",
                                fontWeight: 400,
                                margin: "0px 5px",
                                backgroundColor: "#F7B865",
                              }}
                              onClick={() => {
                                setSelectedTeacher((prev) => {
                                  if (!prev || prev.id !== teacher.id) return teacher;
                                  else return null;
                                });
                              }}
                            >
                              질문하기
                            </div>
                          )
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "25px",
                              borderRadius: "15px",
                              padding: "5px 15px",
                              fontSize: "14px",
                              color: "#FFFFFF",
                              fontWeight: 400,
                              margin: "0px 5px",
                              backgroundColor: "#F7B865",
                            }}
                            onClick={() => {
                              setSelectedTeacherId(teacher.id);
                              setSelectedTeacher((prev) => {
                                if (!prev || prev.id !== teacher.id) return teacher;
                                else return null;
                              });
                            }}
                          >
                            질문하기
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        )}

        {selectedTeacher && (
          <div>
            <input
              id="picture_find"
              type="file"
              accept="image/*"
              style={{
                display: "none",
              }}
              onChange={(e) => {
                if (e.target.files.length === 0) return;
                else if (e.target.files.length === 1) {
                  onSubmit && onSubmit(e, selectedTeacher.id);
                } else {
                  const images = Array.from(e.target.files);
                  setIsSelectTeacherModalOpen(false);
                  setOpenQuestionTypeModal({ isOpen: true, images: images });
                }
              }}
              multiple
            />
            <label
              htmlFor="picture_find"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: 40,
                backgroundColor: style.common.t_color,
                color: "white",
                margin: "32px 0",
                borderRadius: 8,
              }}
            >
              <CameraAlt />
              <div style={{ fontSize: 12, fontWeight: 500, marginLeft: 6 }}>문제 사진 업로드</div>
            </label>
          </div>
        )}

        <div style={{ position: "absolute", left: 0, right: 0, bottom: 30 }}>
          <UiTextBtn
            text="취소"
            btnStyle={{ textAlign: "center" }}
            isTeacher={false}
            isUnderline={false}
            fnClick={() => {
              onCancel();
              if (pickResult) {
                setPickResult(null);
              }
            }}
          />
        </div>
      </div>
    </button>
  );
};

export default SelectTeacherModal;
